<template>
  <div class="ContactPerson">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入承运商名称/编码"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="200">
        </el-table-column> -->
        <el-table-column prop="CarrierCode" label="承运商编号">
        </el-table-column>
        <el-table-column prop="CarrierName" label="承运商名称">
        </el-table-column>
        <el-table-column prop="CarrierPhone" label="联系电话">
        </el-table-column>
        <el-table-column prop="CarrierAddress" label="承运商地址">
        </el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" class="del" @click="handleDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <EditForm ref="editForm" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
import EditForm from "./CarrierForm.vue";
export default {
  props: {},
  components: {
    EditForm,
    radioGroup,
  },
  mounted() {},
  created() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      fileList: [],
      queryParam: { SupplieId: "全部" },
      loading: false,
    };
  },
  methods: {
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$refs.editForm.openForm();
      } else {
        this.$refs.editForm.openForm(null, this.queryParam.SupplieId);
      }
    },
    handleView(row) {
      this.$refs.editForm.openForm(row.Id);
    },
    handleDel(row) {
      this.$confirm("此操作将删除该承运商, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        this.$http
          .post("/Base/Base_Carrier/DeleteData", [row.Id])
          .then((res) => {
            if (res.Success) {
              this.$message.success("操作成功");
              setTimeout(() => {
                loading.close();
              }, 1000);

              this.getDataList()
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/Base/Base_Carrier/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.searchNav .el-button {
  background: #d50700;
  border-color: #d50700;
  color: #fff;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #d50700;
    border-color: #d50700;
    box-shadow: -1px 0 0 0 #d50700;
}
/deep/.el-radio-button__inner:hover {
    color: #d50700;
}
.del {
  color: red;
}
</style>
